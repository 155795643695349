.footerContainer {
    background-color: #3f51b5;
    margin: 0 !important;
    color: #fff;
    padding: 0 20px;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 80px;
    right: 0;
    z-index: 2;
    .MuiGrid-item {
        background-color: #3f51b5 !important;
        color: #fff !important;
    }
}

.blockContainer {
    font-size: 14px;
    &>div {
        display: flex;
        justify-content: space-between;
    }
    .timer-label {
        margin-left: 20px;
        text-align: left;
    }
}

.footer-btn {
    font-size: 14px !important;
    padding: 0 !important;
    float: right;
    .MuiIconButton-label {
        flex-direction: column !important;
    }
    .MuiBox-root {
        margin-top: 2px;
        color: #fff;
    }
    &.with-review {
        float: right;
        margin-top: 10px;
    }
}

.yellow {
    color: #eeeea8 !important;
}

.red {
    color: red !important;
}

.blue {
    color: rgb(63, 63, 187) !important;
}

.marg-left {
   margin-left: 15px !important;
}

.modalButton {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.modalBody {
    text-align: center;
    margin: 30px 0;
}

.modalHeader {
    background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
    padding: 10px;
    color: #fff;
    text-align: center;
}

.redButton {
    background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
}

.MuiButtonBase-root {
    font-weight: normal !important;
    font-size: 13px !important;
}

@media only screen and (max-width: 600px) {
    .MuiButtonBase-root {
        font-size: 11px !important;
    }
    .footerContainer {
        .blockContainer {
            font-size: 11px !important;
        }
    }
}